export class NumberUtils {
  // finds the multiple of 10 that this number is closest to
  public static closestToMultipleOfTen(number: number) {
    let closest = 0;
    let minDistance = Math.abs(number - closest);

    // Loop through multiples of ten from 10 to 100
    for (let i = 10; i <= 100; i += 10) {
      // Calculate distance from the current multiple of ten
      const distance = Math.abs(number - i);

      // Update closest multiple of ten if a closer one is found
      if (distance < minDistance) {
        minDistance = distance;
        closest = i;
      }
    }

    return closest;
  }

  // Formats a number as German floating point with . as 1000 separator and , as decimal separator
  public static germanFloat(value: number, decimalPlaces: number): string {
    const formatter = new Intl.NumberFormat('de-DE', {
      style: 'decimal',
      minimumFractionDigits: decimalPlaces,
      maximumFractionDigits: decimalPlaces
    })
    return formatter.format(value);
  }
}
