import { Assert } from '../assert';
import { SEUnit, Unit } from './unit';
import { Value } from './value';
import { Element } from './element';

export class Storage extends Element {

    static readonly STORAGE_EMPTY_PERCENT: number = 20;
    static readonly STORAGE_FULL_PERCENT: number = 30;

    public capacity: Value;
    public overload: number;

    constructor(name: string, seunitTube: SEUnit, seunitBuffer: SEUnit, valueMin: number, valueMax: number) {
        super(name, seunitTube);
        this.capacity = new Value(seunitBuffer, valueMin, valueMax, valueMin);
        this.overload = 0;
        Assert.assert(this.capacity.getValue() === 0, this.constructor.name, this.name, "HERE");
    }

    public setStorageLevel(value: number): void {
        Assert.assert(true, this.constructor.name, this.name, "");
        this.capacity.setValue(value);
    }

    public getStorageLevelByPercent(): number {
        return this.capacity.getValueByPercent();
    }

    public getInfo(): string {
        let str: string = "*** " + this.constructor.name + " " + this.name + " ***\n";
        super.getInfo(false);
        str += "Capacity = " + this.capacity.getInfo() + "\n";
        return str;
    }

    public overloadHasBeenSolved(): void {
        this.overload = 0;
    }

    public isFull(): boolean {
        return Storage.STORAGE_FULL_PERCENT < this.capacity.getValueByPercent();
    }

    public getPercentOfCapacity(percent: number): number {
        Assert.assert(0 <= percent && percent <= 100, this.constructor.name, this.name, "No percent value.");
        Assert.assert(this.capacity.valueMin < this.capacity.valueMax, this.constructor.name, this.name, "Min not smaller than max.");
        return (this.capacity.valueMax - this.capacity.valueMin) * percent / 100;
    }

    public isEmpty(): boolean {
        return this.capacity.getValueByPercent() < Storage.STORAGE_EMPTY_PERCENT;
    }

    //public hasMinimalLoad(): boolean {
    //    return 20 < this.capacity.getValueByPercent();
    //}

    public step(duration: number = 1): boolean {
        Assert.assert(0 === this.overload, this.constructor.name, this.name, "Overload should be 0.");
        super.step(duration);
        this.overload = this.capacity.addValue(this.getSumImpExp() * duration);
        return 0 < this.overload;
    }

}
