import { SEUnit, Unit } from './unit';
import { Value } from './value';
import { Element } from './element';

export class Tube {

    public name: string = "NONE";
    public value: Value;
    public elementIn: Element | Storage;
    public elementOut: Element | Storage;

    constructor(name: string, seunit: SEUnit, valueMin: number, valueMax: number) {
        this.name = name;
        this.value = new Value(seunit, valueMin, valueMax, 0);
    }

    public setValue(value: number): void {
        this.value.setValue(value);
    }

    public setElementIn(elementIn: Element | Storage): void {
        this.elementIn = elementIn;
    }

    public setElementOut(elementOut: Element | Storage): void {
        this.elementOut = elementOut;
    }

    public setElementInOut(elementIn: Element | Storage, elementOut: Element | Storage): void {
        this.elementIn = elementIn;
        this.elementOut = elementOut;
    }

    public getValue(): number {
        return this.value.value;
    }

    public getInfo(): string {
        let str: string = this.constructor.name + " " + this.name + " (" + this.value.getInfo() + ")";
        return str;
    }

}
