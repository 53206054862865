import tinycolor from "tinycolor2";

export class Sun {
  public defaultColor: string = "#FFFF00";
  public color: string = "#FFFF00";
  public level: number = 50;
  public defaultNumRays: number = 5;

  public setLevelPercent(percent: number): void {
    this.level = this.within0to100(percent);
  }

  // increases sunshine level by some amount
  // the level is made sure to remain within 0 and 100 range
  increaseSunshine(plus: number): void {
    this.level = this.within0to100(this.level + plus);
    this.color = this.colorForSunshineLevel(this.level);
  }

  // decreases sunshine level by some amount
  // the level is made sure to remain within 0 and 100 range
  decreaseSunshine(minus: number): void {
    this.level = this.within0to100(this.level - minus);
    this.color = this.colorForSunshineLevel(this.level);
  }
  colorForSunshineLevel(sunshineLevel: number): string {
    var col = new tinycolor(this.defaultColor);

    const deltaTo50 = sunshineLevel - 50;
    if (deltaTo50 < 0) {
      return col.darken(Math.abs(deltaTo50) / 2.0).toHexString();
    } else if (deltaTo50 > 0) {
      return col.brighten(deltaTo50 / 2.0).toHexString();
    } else {
      return this.defaultColor;
    }
  }

  reset(): void {
    this.level = 50;
    this.color = "#FFFF00";
  }

  within0to100(sunshineLevel: number): number {
    if (sunshineLevel > 100) {
      return 100;
    } else if (sunshineLevel < 0) {
      return 0;
    } else {
      return sunshineLevel;
    }
  }

  // calculate number of rays for given percentage with rays being between 0 and 14
  numberOfRays(): number {
    const sunshineLevel = this.level;
    if (sunshineLevel <= 0) return 0;
    if (sunshineLevel >= 100) return 14;

    const minValue = 0;
    const maxValue = 14;
    const minPercentage = 0;
    const maxPercentage = 100;

    const result = minValue + ((sunshineLevel - minPercentage) / (maxPercentage - minPercentage)) * (maxValue - minValue);
    return Math.floor(result);
  }

  getInfo(): string {
    if (this.level < 30) {
      return "No PV energy available.";
    } else if (70 < this.level) {
      return "PV energy surplus.";
    } else {
      return "PV energy available.";
    }
  }
}
