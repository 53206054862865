import { Func } from './func';

export class FuncLine extends Func {

    // Gradient of line
    private a: number = 0;
    // Offset of line (intersection with y-axis)
    private b: number = 0;

    constructor(
        x1: number, 
        y1: number,
        x2: number, 
        y2: number,
    ) {
        super();
        this.setFromTwoPoints(x1, y1, x2, y2);
    }

    public setFromTwoPoints(
        x1: number, 
        y1: number,
        x2: number, 
        y2: number,
    ): void {
        this.a = (y1 - y2) / (x1 - x2);
        this.b = y1 - this.a * x1;    
    }

    public calc(x: number): number {
        return this.a * x + this.b;
    }

    public getA(): number {
        return this.a;
    }

    public getB(): number {
        return this.b;
    }

}