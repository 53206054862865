export class Plants {

  static readonly FIRST_ROW_START: number = 1;
  static readonly FIRST_ROW_END: number = 11;
  static readonly SECOND_ROW_START: number = 1;
  static readonly SECOND_ROW_END: number = 14;

  private static startGrowthRow(pre: number, start: number, end: number): void {
    const s: number = pre * 100 + start;
    const e: number = pre * 100 + end;
    for (let i = s; i <= e; ++i) {
      const plant = document.querySelector("#plant" + i);
      if (null !== plant) {
        const speeds = [0.005, 0.08, 0.01, 0.015, 0.017, 0.02, 0.03];
        const random = Math.floor(Math.random() * speeds.length);
        const speed = speeds[random];
        plant.setAttribute("animation-mixer", "timeScale: " + speed);
      }
    }
  } 

  private static stopGrowthRow(pre: number, start: number, end: number): void {
    const s: number = pre * 100 + start;
    const e: number = pre * 100 + end;
    for (let i = s; i <= e; ++i) {
      const plant = document.querySelector("#plant" + i);
      if (null !== plant) {
        plant.setAttribute("animation-mixer", "timeScale: 0");
      }
    }
  }

  public static startGrowth(): void {
    this.startGrowthRow(1, this.FIRST_ROW_START, this.FIRST_ROW_END);
    this.startGrowthRow(2, this.SECOND_ROW_START, this.SECOND_ROW_END);
  }

  public static stopGrowth(): void {
    this.stopGrowthRow(1, this.FIRST_ROW_START, this.FIRST_ROW_END);
    this.stopGrowthRow(2, this.SECOND_ROW_START, this.SECOND_ROW_END);
  }
}
