import { SkyColor } from "./SkyColor";

export class Fog {
  public static summerColor: string = "#adcae6";
  public static winterColor: string = "#adc1e6";
  public static transitionColor: string = "#adc5e6";
  public static defaultFarValue: number = 1200;

  static farValue(season: string): number {
    switch (season) {
      case "winter":
        return 300;

      case "transition":
        return 600;

      default:
        return this.defaultFarValue;
    }
  }

  static colorFor(season: string, sunshineLevel: number): string {
    // 50 is supposed to be the default brightness. level varies between 0 and 100
    const deltaToMediumLevel = sunshineLevel - 50.0;
    let col: string;
    switch (season) {
      case "winter":
        col = this.winterColor;
        break;
      case "transition":
        col = this.transitionColor;
        break;
      default:
        col = this.summerColor;
        break;
    }

    return SkyColor.adaptBrightness(col, deltaToMediumLevel);
  }
}
