import { Assert } from '../assert';
import { SEUnit, Unit } from './unit';
import { Value } from './value';
import { Tube } from './tube';


export class Element {

    public name: string = "NONE";
    protected seunit: SEUnit = SEUnit.NONE;

    public imp: Array<Tube>;
    public exp: Array<Tube>;
    public impNeeded: Array<[Tube, number]>;
    public expNeeded: Array<[Tube, number]>;

    constructor(
        name: string,
        seunit: SEUnit,
    ) {
        this.name = name;
        this.seunit = seunit;
        this.imp = new Array<Tube>();
        this.exp = new Array<Tube>();
        this.impNeeded = new Array<[Tube, number]>();
        this.expNeeded = new Array<[Tube, number]>();
    }

    public addTubeImp(tube: Tube) {
        Assert.assert(this.seunit === tube.value.unit.seunit, this.constructor.name, this.name, "Units are not the same.");
        this.imp.push(tube);
    }

    public addTubeExp(tube: Tube) {
        Assert.assert(this.seunit === tube.value.unit.seunit, this.constructor.name, this.name, "Units are not the same.");
        this.exp.push(tube);
    }

    public addTubeImpNeeded(tube: Tube) {
        Assert.assert(this.seunit === tube.value.unit.seunit, this.constructor.name, this.name, "Units are not the same.");
        this.impNeeded.push([tube, 0]);
    }

    public addTubeExpNeeded(tube: Tube) {
        Assert.assert(this.seunit === tube.value.unit.seunit, this.constructor.name, this.name, "Units are not the same.");
        this.expNeeded.push([tube, 0]);
    }

    private getRndForTube(tube: Tube, baseLoad: number): number {
        const val: Value = tube.value;
        const min: number = val.valueMin + baseLoad;
        Assert.assert(min < val.valueMax, this.constructor.name, this.name, "Minimal value regarding to base load should be smaller the maximal value");
        const rndVal: number = Math.floor(Math.random() * (val.valueMax - min + 1)) + min;
        Assert.assert(min <= rndVal && rndVal <= val.valueMax, this.constructor.name, this.name, "Random value not within range");
        return rndVal;
    }

    private setXxxNeeded(arr: Array<[Tube, number]>, tube: Tube, value: number): void {
        for(let e of arr) {
            if(e[0] === tube) {
                e[1] = value;
                return;
            }
        }
        Assert.assert(false, this.constructor.name, this.name, "No element found.");
    }

    public setImpNeeded(tube: Tube, value: number): void {
        this.setXxxNeeded(this.impNeeded, tube, value);
    }

    public setImpNeededRandom(tube: Tube, factor: number, baseLoad: number): number {
        const rnd: number =  this.getRndForTube(tube, baseLoad);
        this.setXxxNeeded(this.impNeeded, tube, rnd * factor);
        return rnd;
    }

    public setExpNeeded(tube: Tube, value: number): void {
        this.setXxxNeeded(this.expNeeded, tube, value);
    }

    public setExpNeededRandom(tube: Tube, factor: number, baseLoad: number): number {
        const rnd: number =  this.getRndForTube(tube, baseLoad);
        this.setXxxNeeded(this.expNeeded, tube, rnd * factor);
        return rnd
    }

    private setToTubeFromArray(arr: Array<[Tube, number]>): void {
        for(let e of arr) {
            e[0].setValue(e[1]);
        }
    }
    public setToTube(): void {
        this.setToTubeFromArray(this.impNeeded);
        this.setToTubeFromArray(this.expNeeded);
    }


    // *** Getter functions ***
    private getXxxNeeded(arr: Array<[Tube, number]>, tube: Tube): number {
        for(let e of arr) {
            if(e[0] === tube) {
                return e[1];
            }
        }
        Assert.assert(false, this.constructor.name, this.name, "No element found.");
        return -1;
    }

    public getImpNeeded(tube: Tube): number {
        return this.getXxxNeeded(this.impNeeded, tube);
    }

    public getExpNeeded(tube: Tube): number {
        return this.getXxxNeeded(this.expNeeded, tube);
    }


    //private set
    //    // Set all not needed tubes to 0
    //    for(let e of this.exp) {
    //        console.log("setting " + this.name + " from " + e.getValue() + " to ");
    //        e.setValue(0);
    //        console.log(e.getValue());
    //    }

    private getSumArrayTube(arr: Array<Tube>): number {
        let sum: number = 0;
        for(let e of arr) {
            sum += e.getValue();
        }
        Assert.assert(0 <= sum, this.constructor.name, this.name, "sum is smaller then 0");
        return sum;
    }

    private getSumArrayPairTubeNumber(arr: Array<[Tube, number]>): number {
        let sum: number = 0;
        for(let e of arr) {
            sum += e[0].getValue();
        }
        Assert.assert(0 <= sum, this.constructor.name, this.name, "sum is smaller then 0");
        return sum;
    }

    protected getSumImpExp(): number {
        let sum: number = 0;
        sum += this.getSumArrayTube(this.imp);
        sum += this.getSumArrayPairTubeNumber(this.impNeeded);
        sum -= this.getSumArrayTube(this.exp);
        sum -= this.getSumArrayPairTubeNumber(this.expNeeded);
        return sum;
    }

    private getInfoArrayTube(arr: Array<Tube>, name: string): string {
        let str: string = "";
        if(0 !== arr.length) {
            str += name + ":";
            for(let e of arr) {
                str += " " + e.getInfo() + ",";
            }
            str += "\n";
        }
        return str;
    }

    private getInfoArrayPairTubeNumber(arr: Array<[Tube, number]>, name: string): string {
        let str: string = "";
        if(0 !== arr.length) {
            str += name + ":";
            for(let e of arr) {
                str += " " + e[0].getInfo() + " setvalue: " + e[1] + ",";
            }
            str += "\n";
        }
        return str;
    }

    public getInfo(withName: boolean = true): string {
        let str: string = "";
        if(withName) {
            str += "*** " + this.constructor.name + " " + this.name + " ***\n";
        }
        str += this.getInfoArrayTube(this.imp, "imp");
        str += this.getInfoArrayTube(this.exp, "exp");
        str += this.getInfoArrayPairTubeNumber(this.impNeeded, "imp needed");
        str += this.getInfoArrayPairTubeNumber(this.expNeeded, "exp needed");
        return str;
    }

    public step_prepare(): void {
        this.setToTube();
    }

    public step(duration: number = 1): boolean {
        //for(let e of this.imp) {
        //    console.log("setting " + this.name + " from " + e.getValue() + " to ");
        //    console.log(e.getValue());
        //    e.setValue(0);
        //}
        return false;
    }

}
