import { Assert } from '../assert';

export class Automode {

    static readonly NUM_TICKS_TO_ACT = 5 * 60;
    static readonly DURATION_TEXT = 8;
    static readonly NUM_TICKS_ACT = [ 
        Automode.DURATION_TEXT, Automode.DURATION_TEXT, Automode.DURATION_TEXT,  67, 5,
        Automode.DURATION_TEXT, Automode.DURATION_TEXT, Automode.DURATION_TEXT, 233, 5 
    ];
    static readonly NUM_TICKS_ACTS = 10;
    static readonly POSITIONS = [ "-23 0.2 10", "-4 30 76", "-3 0.2 20", ]
    static readonly POSITION_IDS = [ "wp8", "wp1", "wp2" ]

    private tick: number = 0;
    private actionTick: number = 0;
    private actionNum: number = 0;

    private isAutomode: boolean = false;

    constructor() {
    }

    private cameraLookUp(): void {
        const cam = document.querySelector("#cameraWrapper");
        const camHead = document.querySelector("#cameraHead");  
        if(null !== cam && null !== camHead) {
            // look slightly up
            cam.setAttribute("rotation", { x: 30, y: 0, z: 0 });
            // Remove look-controls temporarily
            camHead.removeAttribute("look-controls");
            camHead.setAttribute("rotation", { x: 0, y: 0, z: 0 });
        }        
    }

    private cameraLookStraight(): void {
        const cam = document.querySelector("#cameraWrapper");
        const camHead = document.querySelector("#cameraHead");  
        if(null !== cam && null !== camHead) {
            cam.setAttribute("rotation", { x: 0, y: 0, z: 0 });
            camHead.setAttribute("rotation", { x: 0, y: 0, z: 0 });
            // Re-enable look-controls
            camHead.setAttribute("look-controls", "reverseMouseDrag: false");      
        }
    }

    public resetTick(): void {
        this.tick = 0;
        this.actionTick = 0;
        this.actionNum = 0;
        const txt1 = document.querySelector("#automode-text1");
        const txt2 = document.querySelector("#automode-text2");
        const plane = document.querySelector("#automode-plane");
        if(null !== txt1 && null !== txt2 && null != plane) {
            plane.setAttribute("visible", "false");
            txt1.setAttribute("visible", "false");
            txt2.setAttribute("visible", "false");
        }
        if(true === this.isAutomode) {
            this.cameraLookStraight();
            this.removeVideo();
            this.isAutomode = false;
        }
        //console.log("resetTick!!!");
    }

    public hideFootprints(currentElementId: string): void {
        const waypoints = document.querySelectorAll(".waypoint");
        // reset all waypoint footprints
        Array.from(waypoints).forEach((waypoint) => {
          waypoint.setAttribute("visible", "true");
        });
        // hide current waypoint's footprint
        const currentWaypoint = document.querySelector(`#${currentElementId}`);
        currentWaypoint.setAttribute("visible", "false");
    }

    private setText0(): void {
        const cam: any = document.querySelector("#cameraWrapper");
        const plane = document.querySelector("#automode-plane");
        const txt1 = document.querySelector("#automode-text1");
        const txt2 = document.querySelector("#automode-text2");
        if(cam !== null && null !== txt1 && null !== txt2) {
            //console.log("case 0");
            plane.setAttribute("visible", "true");
            txt1.setAttribute("visible", "true");
            txt2.setAttribute("visible", "true");
            txt1.setAttribute("value", "Auf dem Weg zur");
            txt2.setAttribute("value", "Klimapositivität");
            cam.setAttribute("position", Automode.POSITIONS[2]);
            this.hideFootprints(Automode.POSITION_IDS[2]);
        }
    }

    private setText1(): void {
        const cam: any = document.querySelector("#cameraWrapper");
        const txt1 = document.querySelector("#automode-text1");
        const txt2 = document.querySelector("#automode-text2");
        if(cam !== null && null !== txt1 && null !== txt2) {
            //console.log("case 1");
            txt1.setAttribute("value", "Bavarian Green");
            txt2.setAttribute("value", "Data Center");
            cam.setAttribute("position", Automode.POSITIONS[1]);
            this.hideFootprints(Automode.POSITION_IDS[1]);
        }
    }

    private setText2(): void {
        const cam: any = document.querySelector("#cameraWrapper");
        const txt1 = document.querySelector("#automode-text1");
        const txt2 = document.querySelector("#automode-text2");
        if(cam !== null && null !== txt1 && null !== txt2) {
            //console.log("case 2");
            txt1.setAttribute("value", "Bayern macht");
            txt2.setAttribute("value", "Gigabits zu Gemüse");
            cam.setAttribute("position", Automode.POSITIONS[0]);
            this.hideFootprints(Automode.POSITION_IDS[0]);
        }
    }

    private setVideo(videoId: string): void {
        const cam: any = document.querySelector("#cameraWrapper");
        const plane = document.querySelector("#automode-plane");
        const txt1 = document.querySelector("#automode-text1");
        const txt2 = document.querySelector("#automode-text2");
        if(cam !== null && null !== txt1 && null !== txt2) {
            plane.setAttribute("visible", "false");
            txt1.setAttribute("visible", "false");
            txt2.setAttribute("visible", "false");
            txt1.setAttribute("value", "AUTOMODE");
            txt2.setAttribute("value", "AUTOMODE");
            cam.setAttribute("position", Automode.POSITIONS[2]);
            const video = document.querySelector(videoId); // '#video1'
            const videoBox = document.querySelector('#videoBox');
            videoBox.setAttribute('material', `src: #${videoId}`);
            this.hideFootprints(Automode.POSITION_IDS[2]);
            if(false === AFRAME.scenes[0].systems.state.state.videoVisible) {
                AFRAME.scenes[0].emit('toggleVideo');
            }
        }
    }

    private removeVideo(): void {
        if(true === AFRAME.scenes[0].systems.state.state.videoVisible) {
            AFRAME.scenes[0].emit('toggleVideo');
        }
    }

    public action(): void {
        if(Automode.NUM_TICKS_TO_ACT <= this.tick) {

            //console.log("Automode on, tick: " + this.tick + " actionTick: " + this.actionTick + " actionNum: " + this.actionNum);

            if(0 === this.actionTick) {

                // disable tech view if it's currently enabled
                const sportGround = document.querySelector("#sportingGround");
                if (sportGround.getAttribute("model-opacity") != 1) {
                    AFRAME.scenes[0].emit('toggleOpacity');
                }

                // Execute some text or video action
                switch(this.actionNum) {
                    case 0:
                        this.isAutomode = true;
                        this.cameraLookStraight();
                        this.setText0();
                        break;
                    case 1:
                        this.setText1();
                        break;
                    case 2:
                        this.setText2();
                        break;
                    case 3:
                        //console.log("case 3");
                        this.cameraLookUp();                        
                        this.setVideo('video2');
                        break;
                    case 4:
                        //console.log("case 4");
                        this.removeVideo();
                        break;
                    case 5:
                        this.cameraLookStraight();
                        this.setText0();
                        break;
                    case 6:
                        this.setText1();
                        break;
                    case 7:
                        this.setText2();
                        break;
                    case 8:
                        this.cameraLookUp();                        
                        this.setVideo('video1');
                        break;
                    case 9:
                        this.removeVideo();
                        break;
                    default:
                    Assert.assert(false, this.constructor.name, this.name, "switch out ouf range");
                    break;
                }
            }

            // Go on
            if(Automode.NUM_TICKS_ACT[this.actionNum] <= this.actionTick) {
                // Go on
                this.actionNum = (this.actionNum + 1) % Automode.NUM_TICKS_ACTS;
                this.actionTick = 0;
            } else {
                ++this.actionTick;
            }
        }

        // Go on
        ++this.tick;
    }
}