import { Func } from './func';

export class FuncCombined extends Func {

    constructor(
        private funcA: Func, // function A, valid for x <= xseperator
        private funcB: Func, // function B, valid for xseperator < x
        private xseperator: number,
    ) {
        super();
    }

    public calc(x: number): number {
        if(x <= this.xseperator) {
            return this.funcA.calc(x);
        } else {
            return this.funcB.calc(x);
        }
    }

}