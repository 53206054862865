
export enum SEUnit {
    NONE = 0,
    kw,
    kwh,
    lx, // lux
}
  
export class Unit {

    public seunit: SEUnit = SEUnit.NONE;

    constructor(seunit: SEUnit) {
        this.seunit = seunit;
    }

    public getString(): string {
        switch (this.seunit) {
            case 1:
                return "kw";
            case 2:
                return "kwh";
            case 3:
                return "lx";
            default:
                return "ERROR";
        }
    }

 }