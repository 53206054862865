import { Sun } from "./sun";
import { SkyColor } from "./SkyColor";
import { Fog } from './Fog';
import { Plants } from "./Plants";
import { Automode } from "./automode/automode";
import { Control, WeatherCondition } from "./simulation/control";
import { NumberUtils } from "./NumberUtils";

let sun = new Sun();
let automode = new Automode();
let control = new Control();
control.update();


// Sets color
function setColor(name, subname, r, g, b) {
  //console.log("setColor");
  const model = document.querySelector(name); // Important: Unique ID
  //console.log('Imported Model:', importedModel);
  const mesh = model.getObject3D('mesh');
  //console.log('Mesh:', mesh);
  const subobj = mesh.getObjectByName(subname);
  //console.log('Subojgect:', subobj);
  //console.log('Material:', subobj.material);
  subobj.material.color.r = r;
  subobj.material.color.g = g;
  subobj.material.color.b = b;
}
function setColorArray(name, subname, numOfElems, r, g, b) {
  for(let k = 1; k <= numOfElems; ++k) {
    setColor(name, subname + "00" + k, r,g, b);
  }
}
// Set info text
function textInfo(newtext) {
  //console.log("set info text");
  const i = document.querySelector("#textInfo");
 // i.setAttribute('text', 'value', newtext);
}

function updateBuffer(name, subnameCold, subnameWarm, level) {
  const model = document.querySelector(name);
  if(!model) {
    return;
  }
  const mesh = model.getObject3D('mesh');
  if(!mesh) {
    return;
  }
  const cold = mesh.getObjectByName(subnameCold);
  const warm = mesh.getObjectByName(subnameWarm);
  if(!cold || ! warm) {
    return;
  }
  //const wbLevel = control.getHeatbufferLevel01();
  //console.log(": " + level);
  cold.scale.setY(2 - 2 * level);
  warm.scale.setY(2 * level);
}

function updateModels() {
  //console.log("updateModels");
  updateBuffer('#bgdctechnic', 'Warmbuffercold', 'Warmbufferwarm', control.getHeatbufferLevel01());
  updateBuffer('#bgdctechnic', 'Coldbuffercold', 'Coldbufferwarm', control.getColdbufferLevel01());
  /*
  const model = document.querySelector('#bgdctechnic');
  if(!model) {
    return;
  }
  const meshW = model.getObject3D('mesh');
  if(!meshW) {
    return;
  }
  const wbCold = meshW.getObjectByName('Warmbuffercold');
  const wbWarm = meshW.getObjectByName('Warmbufferwarm');
  if(!wbCold || ! wbWarm) {
    return;
  }
  const wbLevel = control.getHeatbufferLevel01();
  console.log(": " + wbLevel);
  wbCold.scale.set(1, 2 - 2 * wbLevel, 1);
  wbWarm.scale.set(1, 2 * wbLevel, 1);
  //*/
}

// eslint-disable-next-line no-undef
AFRAME.registerState({
  initialState: {
    sunColor: sun.color,
    sunLevel: sun.level / 100.0,
    techView: false,
    videoVisible: false,
    skyColor: SkyColor.summerColor,
    fogFarValue: Fog.defaultFarValue,
    fogColor: Fog.summerColor,
    season: "summer",
    plantGrowth: false,
    text: 0,
    pvOutput: "3.000 kW",
    chillerActive: false,
    heatingAchtive: false,
    coldServer: false,
    plantsGrow: true,
    model: 0,
    sunShineVisible: true,
    videoStatusNeedsUpdate: false,
    skyOpacity: 0.5,
    ambientLightIntensity: 1.0,
    greeenHouseHeating01: 0.5,
    greenhouseHeatingFreeCooling01: 0.5,
    serverCooling01: 0.0,
    simulationRunning: true,
    chillerOutputCold01: 0.5,
    chillerOutputWarm01: 0.5,
    sportscenterHeating01: 0.5,
    campusHeating01: 0.5,
    mainDistrictHeating101: 0.5,
    mainDistrictHeating201: 0.5,
    heatPipe: 0.5,
  },
  // State changes are done via events and are handled here.
  handlers: {
    test: function(state, change) {
      //textInfo("Button 'Test' was pressed.");
      //alert(control.getInfo());
      updateModels();
    },
    mouseclick: function(state, change) {
      automode.resetTick();
    },
    simuNext: function(state, change) {
      const str = control.next();
      textInfo(str);
      control.update();
    },
    toggleText: function (state) {
      console.log("toggle text");
      const h = document.querySelector("#textHeading");
      const i = document.querySelector("#textInfo");
      state.text = (state.text + 1) % 3;
      switch(state.text) {
        case 0: 
          h.object3D.visible = true;
          i.object3D.visible = true;
          break;
        case 1: 
          h.object3D.visible = false;
          i.object3D.visible = true;
          break;
        case 2: 
          h.object3D.visible = false;
          i.object3D.visible = false;
          break;
        default:
          Assert.assert(false, this.constructor.name, this.name, "switch out ouf range");
          break;
      }
    },    
    pos0: function(state, change) {
      const camWra = document.querySelector("#cameraWrapper");
      const camera = document.querySelector("[camera]");

      //console.log("Camera: " + camera);
      console.log("camera Rotation is     " + camera.object3D.rotation.x + "," + camera.object3D.rotation.y + "," + camera.object3D.rotation.z);
      camera.object3D.rotation.set(0, 0, 0);
      console.log("camera Rotation set to " + camera.object3D.rotation.x + "," + camera.object3D.rotation.y + "," + camera.object3D.rotation.z);

      camWra.setAttribute("position", "-3 2 20");
      console.log("camWra Rotation is     " + camWra.object3D.rotation.x + "," + camWra.object3D.rotation.y + "," + camWra.object3D.rotation.z);
      //camera.setAttribute("rotation", "0 90 0");
      //camWra.object3D.rotation.set(0, Math.PI / 2, 0); 
      console.log("camWra Rotation set to " + camWra.object3D.rotation.x + "," + camWra.object3D.rotation.y + "," + camWra.object3D.rotation.z);
      camera.object3D.lookAt(0, 0, 0);
    },
    posH: function(state, change) {
      const cam = document.querySelector("#cameraWrapper");
      cam.setAttribute("position", "-4 30 85");
      cam.setAttribute("rotation", "0 0 0");
    },
    pos1: function(state, change) {
      const cam = document.querySelector("#cameraWrapper");
      cam.setAttribute("position", "6 2 8");
      cam.setAttribute("rotation", "0 0 0");
    },
    pos2: function(state, change) {
      const cam = document.querySelector("#cameraWrapper");
      cam.setAttribute("position", "-7 2 6");
      cam.setAttribute("rotation", "0 0 0");
    },
    pos3: function(state, change) {
      const cam = document.querySelector("#cameraWrapper");
      cam.setAttribute("position", "-23 2 5");
    },
    moreSunshine: function (state, change) {
      sun.increaseSunshine(change);
      textInfo(sun.getInfo());
      control.setSunKw(control.getSunKw() + 1000);
      state.sunColor = sun.color;
      state.sunLevel = sun.level / 100.0;
    },
    lessSunshine: function (state, change) {
      sun.decreaseSunshine(change);
      textInfo(sun.getInfo());
      control.setSunKw(control.getSunKw() - 1000);
      state.sunColor = sun.color;
      state.sunLevel = sun.level / 100.0;
    },
    resetSunshine: function(state, change) {
      control.setSunKw(30000);
      sun.reset();
      state.sunColor = sun.color;
      state.sunLevel = sun.level / 100.0;
    },
    summer: function (state) {
      console.log("summer");
      textInfo("Temperature above 20 °C. University needs less heat, but a lot of cold.");
      control.setWeatherCondition(WeatherCondition.GOOD);
      state.season = "summer";
    },
    winter: function (state) {
      console.log("winter");
      textInfo("Temperature below 0 °C. University needs less cold, but a lot of heat.");
      control.setWeatherCondition(WeatherCondition.BAD);
      state.season = "winter";
    },
    transitionPeriod: function (state) {
      console.log("transition");
      textInfo("Temperature between 5 °C and 15 °C. University works heat-autark.");
      control.setWeatherCondition(WeatherCondition.REGULAR);
      state.season = "transition";
    },
    toggleModel: function (state) {
      console.log("toggle model");
      state.model = (state.model + 1) % 2;
      const bgdc = document.querySelector("#bgdc");
      const bgdcpv = document.querySelector("#bgdcpv");
      const container = document.querySelector("#containerclosed");
      switch(state.model) {
        case 0:
          bgdc.setAttribute("visible", true);
          bgdc.setAttribute("animation", "property: model-opacity; from: 0; to: 1; dur: 500");
          bgdcpv.setAttribute("animation", "property: model-opacity; from: 1; to: 0; dur: 500");
          container.setAttribute("animation", "property: model-opacity; from: 1; to: 0; dur: 500");
          setTimeout(container.setAttribute("visible", false), 500);
          setTimeout(bgdcpv.setAttribute("visible", false), 500);
          break;
        case 1:
          bgdcpv.setAttribute("visible", true);
            bgdc.setAttribute("animation", "property: model-opacity; from: 1; to: 0; dur: 500");
            bgdcpv.setAttribute("animation", "property: model-opacity; from: 0; to: 1; dur: 500");
            container.setAttribute("animation", "property: model-opacity; from: 0; to: 1; dur: 500");
          setTimeout(container.setAttribute("visible", true), 500);
          setTimeout(bgdc.setAttribute("visible", true), 500);
            break;
        default:
          Assert.assert(false, this.constructor.name, this.name, "switch out ouf range");
          break;
        }
    },
    toggleOpacity: function (state) {
      console.log("toggle Opacity");
      const plane = document.querySelector("#sportingGround");
      const sgNearby = document.querySelector("#sportingGroundNearby");
      const waypoints = document.querySelectorAll(".waypoint");
      if (state.techView == true) {
        plane.setAttribute("visible", true);
        plane.setAttribute(
          "animation",
          "property: model-opacity; from: 0.2; to: 1; dur: 1000"
        );
        // If not visible==false then text behind sgNearby is not shown.
        sgNearby.setAttribute(
          "visible",
          true
        );
        state.techView = false;
        automode.hideFootprints("wp2");
      } else {
        plane.setAttribute(
          "animation",
          "property: model-opacity; from: 1; to: 0.2; dur: 1000"
        );
        // setTimeout(plane.setAttribute("visible", false), 1100);
        sgNearby.setAttribute(
          "visible",
          false
        );
        state.techView = true;
        const cam = document.querySelector("#cameraWrapper");
        cam.setAttribute("position", "-3 0.2 20");
        // hide all waypoints.
        waypoints.forEach((waypoint) => {
          waypoint.setAttribute("visible", "false");
        });
      }
    },
    toggleVideo: function (state) {
      console.log("toggle Video");
      const sunbeams = document.getElementById("sunbeams");
      const sun = document.getElementById("sun");
      const videoBox = document.querySelector("#videoBox");
      const cam = document.querySelector("#cameraWrapper");
      const camHead = document.querySelector("#cameraHead");
      if (state.videoVisible == true) {
        const videoEl = videoBox.getAttribute('material').src
        videoEl.pause();
        videoEl.currentTime = 0;
        videoBox.setAttribute(
          "animation",
          "property: position; to: -14.4 -9 0; dur: 4000"
        );
        videoBox.classList.remove("clickable");
        state.videoStatusNeedsUpdate = true;
        state.ambientLightIntensity = 1.0;
        /*
        // look straight again
        cam.setAttribute("rotation", { x: 0, y: 0, z: 0 });
        camHead.setAttribute("rotation", { x: 0, y: 0, z: 0 });
        // Re-enable look-controls
        camHead.setAttribute("look-controls", "reverseMouseDrag: false");
        //*/
      } else {
        videoBox.setAttribute(
          "animation",
          "property: position; to: -14.4 15 0; dur: 4000"
        );
        state.videoVisible = true;
        state.ambientLightIntensity = 0.1;
        videoBox.classList.add("clickable");
        sunbeams.setAttribute("visible", false);
        sun.setAttribute("visible", false);
        /*
        // look slightly up
        cam.setAttribute("rotation", { x: 30, y: 0, z: 0 });
        // Remove look-controls temporarily
        camHead.removeAttribute("look-controls");
        camHead.setAttribute("rotation", { x: 0, y: 0, z: 0 });
        //*/
        // autostart video after it's up in the air
        setTimeout(() => {
           const videoBox = document.querySelector("#videoBox");
           togglePlay(videoBox);
           }, 4200);
      }
    },
    toggleSimulation: function (state) {
      state.simulationRunning = !state.simulationRunning;
      if (state.simulationRunning) {
        console.log("starting simulation");
        document.querySelector("#pause-button").classList.remove("hidden");
        document.querySelector("#play-button").classList.add("hidden");
      } else {
        console.log("pausing simulation");
        document.querySelector("#pause-button").classList.add("hidden");
        document.querySelector("#play-button").classList.remove("hidden");
      }
    },
    toggleInfobox: function (state) {
      const infoBox = document.querySelector("#infoBox");
      if (infoBox.classList.contains("hidden")) {
        infoBox.classList.remove("hidden");
      } else {
        infoBox.classList.add("hidden");
        }
    },
    togglePlantGrowth: function (state) {
      console.log("toggle plant growth");
      state.plantGrowth = !state.plantGrowth;
      if (state.plantGrowth == true) {
        Plants.startGrowth();
      } else {
        Plants.stopGrowth();
      }
    },
    simulateNextStep: function (state, _) {
      if (state.simulationRunning) {
        control.next();
        control.update();
        automode.action();
        //console.log("set sun light: " + control.getSolarPowerPercent());
        const lightPowerPercent = control.getSolarPowerPercent();
        sun.setLevelPercent(lightPowerPercent);
        let lightAmbient = document.querySelector("#ambient-light");
        lightAmbient.setAttribute('light', 'intensity', 0.1 + lightPowerPercent / 111.2);
        updateModels();
      }
    },
  },
  // is executed afterwards
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  computeState: function (newState, eventPayload) {
    newState.sunShineVisible = control.getPvOutputKw() > 0;
    if (newState.videoStatusNeedsUpdate == true) {
      const videoBox = document.querySelector("#videoBox");
      const videopos = videoBox.getAttribute("position");
      const sunbeams = document.getElementById("sunbeams");
      const sun = document.getElementById("sun");
      // need to update video visibility after the animation is finished
      if (videopos.equals(new THREE.Vector3(-14.4, -9, 0))) {
        newState.videoVisible = false;
        newState.videoStatusNeedsUpdate = false;
        sunbeams.setAttribute("visible", true);
        sun.setAttribute("visible", true);
      }
    }
    if (newState.videoVisible == false) {
      newState.skyColor = SkyColor.colorFor(newState.season, sun.level);
      newState.fogFarValue = 300;
      newState.fogColor = Fog.colorFor(newState.season, sun.level);
      newState.skyOpacity = 0.5;
    } else {
      newState.skyColor = "#0C0D18";
      newState.fogFarValue = Fog.farValue(newState.season);
      newState.fogColor = "black";
      newState.skyOpacity = 1;
      newState.sunShineVisible = false;
    }
    newState.pvOutput = NumberUtils.germanFloat(control.getPvOutputKw(), 0) + " kW";
    newState.greeenHouseHeating01 = control.getGreenhouseHeating01();
    newState.heatPipe = control.getGreenhouseHeating01();
    newState.greenhouseHeatingFreeCooling01 = control.getGreenhouseHeatingFreeCooling01();
    newState.serverCooling01 = control.getServerCooling01();
    newState.chillerOutputCold01 = control.getChillerOutputCold01();
    newState.chillerOutputWarm01 = control.getChillerOutputHeat01();
    newState.sportscenterHeating01 = control.getSportscenter01();
    newState.campusHeating01 = control.getDistrictHeating01();
    newState.mainDistrictHeating101 = control.getMainDistrictHeating101();
    newState.mainDistrictHeating201 = control.getMainDistrictHeating201();

    // update texture
    const plane = document.querySelector("#sportingGround");
    plane.setAttribute('model-texture', newState.season);
  },
});
