import tinycolor from "tinycolor2";

export class SkyColor {
  public static summerColor: string = "#addde6";
  public static winterColor: string = "#4188ff";
  public static transitionPeriodColor: string = "#91BAD6";

  public static colorFor(season: string, sunshineLevel: number) {
    // 50 is supposed to be the default brightness. level varies between 0 and 100
    const deltaToMediumLevel = sunshineLevel - 50.0;
    switch (season) {
      case "summer": {
        return this.adaptBrightness(this.summerColor, deltaToMediumLevel);
      }
      case "winter": {
        return this.adaptBrightness(this.winterColor, deltaToMediumLevel);
      }
      case "transition": {
        return this.adaptBrightness(
          this.transitionPeriodColor,
          deltaToMediumLevel
        );
      }
      default: {
        throw new Error("Unsupported season " + season);
      }
    }
  }

  public static adaptBrightness(hex: string, percent: number): string {
    var col = new tinycolor(hex);
    if (percent < 0) {
        return col.darken(Math.abs(percent) * 1.5).toHexString();
    } else {
        return col.brighten(percent * 1.5).toHexString();
    }
  }
}
