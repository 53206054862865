import { Assert } from '../assert';
import { NumberUtils } from '../NumberUtils';
import { Value } from './value';
import { SEUnit, Unit } from './unit';
import { Tube } from './tube';
import { Element } from './element';
import { Storage } from './storage';
import { FuncLine } from '../misc/funcLine';
import { FuncCombined } from '../misc/funcCombined';

export enum WeatherCondition {
    BAD = 0,
    REGULAR,
    GOOD,
}

export enum ChillerStatus {
    OFF = 0,
    ON_PV_AVAILABLE,
    ON_HEATPUMP,
    ON_CHILLER,
}

export enum Weather {
    SNOW = 0,
    NO_SNOW = 1,
}

export class Control {

    // Sun maximum power in KW
    static readonly SUN_MAX: number = 600;
    // Maximal server power
    static readonly SERVER_POWER_MAX: number = 80;
    // Head demand of campus below that the district heating is cut off
    static readonly HEAT_DEMAND_TO_CUT_CAMPUS_OF_DISTRICT_HEATING = 300;
    // Percentage of sun power converted to electrical power
    static readonly SUN_TO_ELECTRICITY = 0.2; // 120 kW
    // Maximal demand of heat of campus in kW
    static readonly HEAT_DEMAND_CAMPUS_MAX = 550; // Calculated was 1370
    // Heat demand of greenhouse in percent of heat demand of campus
    static readonly HEAT_DEMAND_GREENHOUSE_FACTOR = 0.04;
    // Maximal input power of chiller
    static readonly CHILLER_POWER_MAX: number = 25;
    // Factor of chiller on cold side
    static readonly CHILLER_COLD_FACTOR: number = 4;
    // Factor of chiller on warm side
    static readonly CHILLER_WARM_FACTOR: number = 5;
    // Ratio between 0 and 1 when free cooling is possible (0 is never)
    static readonly SERVER_FREE_COOLING_POSSIBLE_RATION = 0.1;
    // Base load of servers
    static readonly SERVER_BASE_LOAD = 20;
    // Factor of local PV production on BGDC
    //static readonly PV_LOCAL_PROD_FACTOR: number = 0.01;
    // Power needed by showers
    static readonly SHOWERS_POWER_MAX: number = 10;
    // Solar power factor of season below that there is snow
    static readonly SOLAR_POWER_FACTOR_SEASON_SNOW: number = 0.3;
    // PUE value if free cooling is possible
    static readonly PUE_FREE_COOLING = 1.1;
    // Value of energy needed on campus below that the power plant is turned off
    static readonly POWER_PLANT_OFF = 100;
    // Percent value to pass heat to district heating system
    static readonly HEAT_TO_DISTRICT_HEATING_PERCENT = 3;
    // Capacity of cold buffer
    static readonly CAPACITY_COLD_BUFFER = 500;
    // Capacity of warm buffer
    static readonly CAPACITY_WARM_BUFFER = 1000;

    // Daytime and season
    static readonly INTERTICKS_DURING_DAYLIGHT = 3; // 3
    static readonly INTERTICKS_NIGHT_OFFSET = 0.3;
    static readonly DAYS_PER_MONTH = 28;
    static readonly DAYS_PER_YEAR = Control.DAYS_PER_MONTH * 12;
    static readonly DAYS_PER_SEASON: number = 6;
    static readonly TICKS_PER_DAY: number = 24; // 24
    private intertick: number = 0; // Interticks make day last longer than night
    private tick: number = 0; // from 0 to TICKS_PER_DAY - 1, more or less hours
    private day: number = 0; // from 0 to DAYS_PER_SEASON - 1
    private season: number = 0; // winter = 0, ..., fall = 3

    // Array with all elements like PV, buffers, chiller, tubes, etc.
    private all: Array<Element | Storage>;

    // Elements and buffers
    private chiller: Element;
    private heatBuffer: Storage;
    private coldBuffer: Storage;
    private outsideExchangerHeat: Element;
    private outsideExchangerCold: Element;
    private greenhouseHeating: Element;
    private serverCooling: Element;
    private sun: Element;
    private pv: Element;
    private powerGrid: Element;
    private server: Element;
    private sportscenterHeating: Element;
    private districtHeatingHeating: Element;
    private powerPlant: Element;
    private campus: Element;

    // Tubes
    // Water
    private tubeChillerHeatStore: Tube;
    private tubeChillerColdStore: Tube;
    private tubeHeatStoreExchanger: Tube;
    private tubeColdStoreExchanger: Tube;
    private tubeHeatStoreGreenhouse: Tube;
    private tubeColdStoreServer: Tube;
    private tubeHeatStoreSportscenter: Tube;
    private tubeHeatStoreDistrictHeating: Tube;
    private tubePowerPlantDistrictHeating: Tube;
    private tubeDistrictHeatingCampus: Tube;
    // Photones
    private tubeSunPv: Tube;
    // Electricity
    private tubePvPowerGrid: Tube;
    private tubePowerGridServer: Tube;

    // Other values
    private weatherCondition: WeatherCondition = WeatherCondition.REGULAR;
    private heatDemandCampusFunc: FuncCombined;
    private serverPowerFactor: number = 1.0; // Server input power may be reduced due to energy leak
    private solarPowerSeason021: number = 1; // Solar power of the current season between 0.2 and 1
    private solarPowerDay01: number = 1; // Solar power of the day between 0 and 1
    private chillerPowerInput: number = 0; // Electric input of chiller
    private chillerStatus: ChillerStatus = ChillerStatus.OFF;
    private chillerFreeCoolingPossible: boolean = false;
    private isPowerPlantOff: boolean = false;

    constructor()  {
        this.all = new Array<Element | Storage>();

        // Elements
        this.sun = this.newElement("Sun");
        this.pv = this.newElement("PV");
        this.server = this.newElement("Server");
        this.powerGrid = this.newElement("Power grid");
        this.chiller = this.newElement("Chiller");
        this.heatBuffer = this.newStorage("HeatBuffer", 0, Control.CAPACITY_WARM_BUFFER);
        this.coldBuffer = this.newStorage("ColdBuffer", 0, Control.CAPACITY_COLD_BUFFER);
        this.outsideExchangerHeat = this.newElement("Outside heat exchanger");
        this.outsideExchangerCold = this.newElement("Outside cold exchanger");
        this.greenhouseHeating = this.newElement("Greenhouse heating");
        this.serverCooling = this.newElement("Server cooling");
        this.sportscenterHeating = this.newElement("Sportscenter heating");
        this.districtHeatingHeating = this.newElement("Heating for district heating");
        this.powerPlant = this.newElement("Power plant");
        this.campus = this.newElement("Campus");

        // Sun - PV
        this.tubeSunPv = new Tube("Tube Sun-PV", SEUnit.kw, 0, Control.SUN_MAX);
        this.connectNeeded2NotNeeded(this.sun, this.tubeSunPv, this.pv);

        // PV - Power grid
        this.tubePvPowerGrid = new Tube("Tube PV-Power grid", SEUnit.kw, 0, Control.SUN_MAX * Control.SUN_TO_ELECTRICITY);
        this.connectNeeded2NotNeeded(this.pv, this.tubePvPowerGrid, this.powerGrid);

        // Power grid - Server
        this.tubePowerGridServer = new Tube("Tube PowerGrid-Server", SEUnit.kw, 0, Control.SERVER_POWER_MAX);
        this.connectNotNeeded2Needed(this.powerGrid, this.tubePowerGridServer, this.server);
        
        // Chiller - Heat buffer
        this.tubeChillerHeatStore = new Tube("Tube Chiller-HeatBuffer", SEUnit.kw, 0, Control.CHILLER_POWER_MAX * Control.CHILLER_WARM_FACTOR);
        this.connectNeeded2NotNeeded(this.chiller, this.tubeChillerHeatStore, this.heatBuffer);

        // Chiller - Cold buffer
        this.tubeChillerColdStore = new Tube("Tube Chiller-ColdBuffer", SEUnit.kw, 0, Control.CHILLER_POWER_MAX * Control.CHILLER_COLD_FACTOR);
        this.connectNeeded2NotNeeded(this.chiller, this.tubeChillerColdStore, this.coldBuffer);
        
        // Cold buffer - Outside heat exchanger
        this.tubeColdStoreExchanger = new Tube("Tube ColdBuffer-Exchanger", SEUnit.kw, 0, 200);
        this.connectNeeded2NotNeeded(this.coldBuffer, this.tubeColdStoreExchanger, this.outsideExchangerCold);

        // Cold buffer - Server cooling
        this.tubeColdStoreServer = new Tube("Tube ColdBuffer-Server", SEUnit.kw, 0, Control.CHILLER_POWER_MAX);
        this.connectNotNeeded2Needed(this.coldBuffer, this.tubeColdStoreServer, this.serverCooling);

        // Heat buffer - Greenhouse heating
        this.tubeHeatStoreGreenhouse = new Tube("Tube HeatBuffer-Greenhouse", SEUnit.kw, 0, Control.HEAT_DEMAND_CAMPUS_MAX * Control.HEAT_DEMAND_GREENHOUSE_FACTOR);
        this.connectNotNeeded2Needed(this.heatBuffer, this.tubeHeatStoreGreenhouse, this.greenhouseHeating);

        // Heat buffer - Sportscenter
        this.tubeHeatStoreSportscenter = new Tube("Tube HeatBuffer-Sportscenter", SEUnit.kw, 0, Control.SHOWERS_POWER_MAX);
        this.connectNotNeeded2Needed(this.heatBuffer, this.tubeHeatStoreSportscenter, this.sportscenterHeating);

        // Heat buffer - Outside heat exchanger
        this.tubeHeatStoreExchanger = new Tube("Tube HeatBuffer-Exchanger", SEUnit.kw, 0, 200);
        this.connectNeeded2NotNeeded(this.heatBuffer, this.tubeHeatStoreExchanger, this.outsideExchangerHeat);

        // Heat buffer - District heating heating
        this.tubeHeatStoreDistrictHeating = new Tube("Tube HeatBuffer-DistrictHeatingHeating", SEUnit.kw, 0, Control.HEAT_TO_DISTRICT_HEATING_PERCENT / 100 * Control.CAPACITY_WARM_BUFFER);
        this.connectNotNeeded2Needed(this.heatBuffer, this.tubeHeatStoreDistrictHeating, this.districtHeatingHeating);

        // Power plant - District heating heating
        this.tubePowerPlantDistrictHeating = new Tube("Tube PowerPlant-DistrictHeating", SEUnit.kw, 0, Control.HEAT_DEMAND_CAMPUS_MAX);
        this.connectNotNeeded2Needed(this.powerPlant, this.tubePowerPlantDistrictHeating, this.districtHeatingHeating);

        // District heating heating - Power plant
        this.tubeDistrictHeatingCampus = new Tube("Tube DistrictHeating-Campus", SEUnit.kw, 0, Control.HEAT_DEMAND_CAMPUS_MAX);
        this.connectNotNeeded2Needed(this.districtHeatingHeating, this.tubeDistrictHeatingCampus, this.campus);


        // Set values
        this.calcChillerFromInput(Control.CHILLER_POWER_MAX);
        this.greenhouseHeating.setImpNeeded(this.tubeHeatStoreGreenhouse, 40);
        this.serverCooling.setImpNeeded(this.tubeColdStoreServer, Control.SERVER_POWER_MAX / 2);
        this.sun.setExpNeeded(this.tubeSunPv, Control.SUN_MAX);
        this.pv.setExpNeeded(this.tubePvPowerGrid, Control.SUN_MAX * Control.SUN_TO_ELECTRICITY);
        this.server.setImpNeeded(this.tubePowerGridServer, Control.SERVER_POWER_MAX / 2);

        // Others
        this.heatDemandCampusFunc = new FuncCombined(
            new FuncLine(0.1, Control.HEAT_DEMAND_CAMPUS_MAX, 0.7, 20), 
            new FuncLine(0, 20, 1, 20),
            0.7
        );

    }


    // *** Help functions to add new elements ***

    private connectNeeded2NotNeeded(elem1: Element, tube: Tube, elem2: Element) {
        elem1.addTubeExpNeeded(tube);
        elem2.addTubeImp(tube);
    }

    private connectNotNeeded2Needed(elem1: Element, tube: Tube, elem2: Element) {
        elem1.addTubeExp(tube);
        elem2.addTubeImpNeeded(tube);
    }

    private newElement(
        name: string,
    ) : Element {
        let elem: Element = new Element(name, SEUnit.kw);
        this.all.push(elem);
        return elem;
    }

    private newStorage(
        name: string,
        capMin: number,
        capMax: number,
    ) : Storage {
        let elem: Storage = new Storage(name, SEUnit.kw, SEUnit.kwh, capMin, capMax);
        this.all.push(elem);
        return elem;
    }


    
    // ************************************************************************
    // *** Simulation/control of scene ***
    // ************************************************************************

    private next_intertick(): boolean {
        ++this.intertick;
        if(Control.TICKS_PER_DAY * Control.INTERTICKS_NIGHT_OFFSET <= this.tick && this.tick <= (1.0 - Control.INTERTICKS_NIGHT_OFFSET) * Control.TICKS_PER_DAY) {
            // Day time
            if(Control.INTERTICKS_DURING_DAYLIGHT <= this.intertick) {
                this.intertick = 0;
                return true;
            } else {
                return false;
            }
        } else {
            // Night time
            this.intertick = 0;
            return true;
        }
    }

    private next_timeControle(): void {
        ++this.tick;
        if(Control.TICKS_PER_DAY <= this.tick) {
            this.tick = 0;
            ++this.day;
            if(Control.DAYS_PER_SEASON <= this.day) {
                this.day = 0;
                this.season = (this.season + 1) % 4;
            }
        }
    }

    // Controls the storages
    private controlStorage(storage: Storage): void {
        Assert.assert(0 <= storage.overload, this.constructor.name, this.name, "Overload should be greater than 0.");
        if(storage === this.heatBuffer)  {
            this.tubeHeatStoreExchanger.setValue(storage.overload);
        } else if(storage === this.coldBuffer) {
            this.tubeColdStoreExchanger.setValue(storage.overload);
        } else {
            Assert.assert(false, this.constructor.name, this.name, "No storage " + storage.name + " was found, no storage treatment was performed.");
        }
        storage.overloadHasBeenSolved();
        Assert.assert(0 === storage.overload, this.constructor.name, this.name, "Overload should be 0.");
    }

    // Calculates the heat demand of the campus and greenhouse
    private calcHeatDemandCampusGreenhouseShowers(): void {
        const facHeatDemand: number = this.getSolarPowerSeason021() * this.getWeatherFactor();
        Assert.assert(0.1 - Assert.EPS_DOUBLE <= facHeatDemand && facHeatDemand <= 1 + Assert.EPS_DOUBLE, this.constructor.name, this.name, "Factor of heat demand not within range");
        const heatDemandCampus: number = this.heatDemandCampusFunc.calc(facHeatDemand);
        // Campus
        this.campus.setImpNeeded(this.tubeDistrictHeatingCampus, heatDemandCampus);
        // Greenhouse
        this.chillerFreeCoolingPossible;
        const valGrnHtg: number = this.chillerFreeCoolingPossible ? 0 : heatDemandCampus * Control.HEAT_DEMAND_GREENHOUSE_FACTOR;
        this.greenhouseHeating.setImpNeeded(this.tubeHeatStoreGreenhouse, valGrnHtg);
        // Showers sportcenter
        this.setHeatDemandShowersKw(heatDemandCampus < Control.HEAT_DEMAND_TO_CUT_CAMPUS_OF_DISTRICT_HEATING ? Control.SHOWERS_POWER_MAX : 0);
        // Checks if free cooling is possible
        this.chillerFreeCoolingPossible = this.getSolarPower01() < Control.SERVER_FREE_COOLING_POSSIBLE_RATION;
    }

    // Calculates the current power needed of servers and needed cooling
    private calc_server_power_cooling(): void {
        const rnd: number = this.server.setImpNeededRandom(this.tubePowerGridServer, this.serverPowerFactor, Control.SERVER_BASE_LOAD);
        //const cooling: number = rnd * (1 - this.getSolarPowerSeason021());
        //this.calcChillerFromOutputCold(this.chillerFreeCoolingPossible ? 0 : rnd);
        this.serverCooling.setImpNeeded(this.tubeColdStoreServer, this.chillerFreeCoolingPossible ? 0 : rnd);
        //console.log("Server: " + this.getServerCoolingKw() + " kW, Cooling: " + this.getServerCoolingKw() + " kW");
    }

    // Sets the chiller from electrical input
    private calcChillerFromInput(inputPowerKw: number): void {
        this.chillerPowerInput = inputPowerKw;
        Assert.assert(0 <= this.chillerPowerInput && this.chillerPowerInput <=  Control.CHILLER_POWER_MAX, this.constructor.name, this.name, "Input power of chiller not correct");
        this.chiller.setExpNeeded(this.tubeChillerHeatStore, this.chillerPowerInput * Control.CHILLER_WARM_FACTOR);
        this.chiller.setExpNeeded(this.tubeChillerColdStore, this.chillerPowerInput * Control.CHILLER_COLD_FACTOR);
        Assert.assert(0 <= this.getChillerOutputColdKw() && this.getChillerOutputColdKw() <= Control.CHILLER_POWER_MAX * Control.CHILLER_COLD_FACTOR, this.constructor.name, this.name, "Output of chiller on cold side not correct");
        Assert.assert(0 <= this.getChillerOutputHeatKw() && this.getChillerOutputHeatKw() <= Control.CHILLER_POWER_MAX * Control.CHILLER_WARM_FACTOR, this.constructor.name, this.name, "Output of chiller on warm side not correct");
    }

    // Sets the chiller from thermal cold output
    private calcChillerFromOutputCold(outputColdKw: number): void {
        const chillerPowerInput: number = outputColdKw / Control.CHILLER_COLD_FACTOR;
        this.calcChillerFromInput(chillerPowerInput);
    }

    // Sets the chiller from thermal warm output
    private calcChillerFromOutputWarm(outputWarmKw: number): void {
        const chillerPowerInput: number = outputWarmKw / Control.CHILLER_WARM_FACTOR;
        this.calcChillerFromInput(chillerPowerInput);
    }

    // Sets the output to the district heating
    private setOutputToDistrictHeating(percentValue: number): void {
        this.setDistrictHeatingKw(this.heatBuffer.getPercentOfCapacity(percentValue));
    }

    // Calculates the power plant
    private calcPowerPlantDistrictHeating(): void {
        const heatDemandCampus: number = this.getHeatDemandCampusKw();
        this.setHeatOutputPowerPlantKw(heatDemandCampus < Control.POWER_PLANT_OFF ? 0 : heatDemandCampus);
    }



    private next_simulation(): string {
        let str: string = "";

        // Prepare step, set values to tubes
        for(let elem of this.all) {
            elem.step_prepare();
        }

        for(let elem of this.all) {
            //console.log("Name of elem: " + elem.name);
            if(elem instanceof Storage) {
                let elemStorage: Storage = elem as Storage;
                const hasOverload: boolean = elem.step();
                this.controlStorage(elemStorage);
                if(hasOverload) {
                    str += elem.name + " > overload: " + elemStorage.overload + "\n";
                    //this.controlStorage(elemStorage);
                } else {

                }
            }
        }

        // Calculate current solar power
        this.calcSolarPower();
        // Head demand of campus
        this.calcHeatDemandCampusGreenhouseShowers();

        // Randomize needed server power
        this.calc_server_power_cooling();

        // Check if buffers are empty
        if(this.coldBuffer.isEmpty()) {
            this.chillerStatus = ChillerStatus.ON_CHILLER;
            this.calcChillerFromInput(Control.CHILLER_POWER_MAX);
        } else if(this.heatBuffer.isEmpty()) {
            this.chillerStatus = ChillerStatus.ON_HEATPUMP;
            this.calcChillerFromInput(Control.CHILLER_POWER_MAX);
        } else {
            const powerPvKw: number = this.getPvOutputKw();
            const powerServer: number = this.getServerPowerInputKw();
            const powerAvailable: number = powerPvKw - powerServer;
            if(0 < powerAvailable && (!this.coldBuffer.isFull() || !this.heatBuffer.isFull())) {
                this.chillerStatus = ChillerStatus.ON_PV_AVAILABLE;
                this.calcChillerFromInput(powerAvailable < Control.CHILLER_POWER_MAX ? powerAvailable : Control.CHILLER_POWER_MAX);
            } else {
                this.chillerStatus = ChillerStatus.OFF;
                this.calcChillerFromInput(0);    
            }
        }

        // Check if heat buffer is full
        this.setOutputToDistrictHeating(this.heatBuffer.isFull() ? Control.HEAT_TO_DISTRICT_HEATING_PERCENT : 0);

        // Cut of campus from power plant
        //console.log("Heat Demand Campus: " + this.getHeatDemandCampusKw());
        if(this.getHeatDemandCampusKw() < Control.HEAT_DEMAND_TO_CUT_CAMPUS_OF_DISTRICT_HEATING) {
            this.setHeatOutputPowerPlantKw(0);
            this.isPowerPlantOff = true;
        } else {
            this.setHeatOutputPowerPlantKw(this.getHeatDemandCampusKw());
            this.isPowerPlantOff = false;
        }

        str += this.getInfo();
        return str;
    }

    public next(duration: number = 1): string {
        let str: string = "";
        if(this.next_intertick()) {
            this.next_timeControle();
            str += this.next_simulation();
        }
        return str;
    }

    private updateText(idName: string, text: string, preFix: string = "", postFix: string = ""): void {
        const i = document.querySelector("#" + idName);
        i.innerHTML = preFix + text + postFix;
    }

    public update(): void {
        this.updateText("textDateTime", this.getDateTime(), "Datum: ", "");
        //this.updateText("textSun", NumberUtils.germanFloat(this.getSolarPowerKw(), 0), "Leistung Sonne: ", " kW");
        this.updateText("textPv", NumberUtils.germanFloat(this.getPvOutputKw(), 0), "Leistung PV: ", " kW");
        this.updateText("textServerElectricalInput", NumberUtils.germanFloat(this.getServerPowerInputKw(), 0), "Server Aufnahmeleistung: ", " kW");
        //this.updateText("textServerCooling", NumberUtils.germanFloat(this.getServerCoolingKw(), 0), "Server Kühlbedarf: ", " kW");
        //this.updateText("textChillerStatus", "" + this.getChillerStatus(), "KM/WP Status: ", "");
        const isChiller: boolean = ChillerStatus.ON_CHILLER === this.chillerStatus;
        const txtChillerInput: string = NumberUtils.germanFloat(this.chillerPowerInput, 0);
        const txtNull: string = NumberUtils.germanFloat(0, 0);
        this.updateText("textChillerOnlyElectrical", isChiller ? txtChillerInput : txtNull, "Leistung Kältemaschine (el.): ", " kW");
        this.updateText("textHeatpumpOnlyElectrical", isChiller ? txtNull : txtChillerInput, "Leistung Wärmepumpe (el.): ", " kW");
        //this.updateText("textChillerElectrical", NumberUtils.germanFloat(this.chillerPowerInput, 0), " kW");
        //this.updateText("textChillerOutputCold", NumberUtils.germanFloat(this.getChillerOutputColdKw(), 0), "Leistung Kälteoutput: ", " kW");
        //this.updateText("textChillerOutputWarm", NumberUtils.germanFloat(this.getChillerOutputHeatKw(), 0), "Leistung Wärmeoutput: ", " kW");
        //this.updateText("textHeatBufferStorageLevel", NumberUtils.germanFloat(this.getHeatBufferStorageLevelPercent(), 2), "Füllstand Wärmespeicher: ", "%");
        //this.updateText("textColdBufferStorageLevel", NumberUtils.germanFloat(this.getColdBufferStorageLevelPercent(), 2), "Füllstand Kältespeicher: ", "%");
        //this.updateText("textHeatDemandCampus", NumberUtils.germanFloat(this.getHeatDemandCampusKw(), 2), "Wärmebedarf Campus: ", " kW");
        //this.updateText("textTEtoGreenhouse", NumberUtils.germanFloat(this.getGreenhouseHeatingKw(), 0), "Wärme zu Gewächshaus: ", " kW");
        //this.updateText("textTEtoSportscenter", NumberUtils.germanFloat(this.getSportscenterKw(), 0), "Wärme zu Duschen: ", " kW");
        //this.updateText("textTEtoDistrictHeation", NumberUtils.germanFloat(this.getDistrictHeatingKw(), 0), "Wärme zu Nahwärme: ", " kW");
        this.updateText("textREF", NumberUtils.germanFloat(this.getREF(), 2), "Anteil Leistung PV (REF): ", "");
        this.updateText("textPUE", NumberUtils.germanFloat(this.getPUE(), 2), "Effizienz (PUE): ", "");

        
        //this.updateText("textChillerFreeCooling", this.chillerFreeCoolingPossible ? "ja" : "nein", "Freie Kühlung möglich: ", "");
    }



    // ************************************************************************
    // *** Interface ***
    // ************************************************************************


    // *** Help functions ***

    private get01ImpNeeded(element: Element | Storage, tube: Tube): number {
        const max: number = tube.value.valueMax;
        const val: number = element.getImpNeeded(tube);
        const val01: number = val / max;
        //Assert.assert(0 <= val01 && val01 <= 1, this.constructor.name, this.name, "Value is not between 0 and 1, value is: " + val01 + " = " + val + "/" + max);

        return Math.max(0, Math.min(1, val01));
    }

    private get01ExpNeeded(element: Element | Storage, tube: Tube): number {
        const max: number = tube.value.valueMax;
        const val: number = element.getExpNeeded(tube);
        const val01: number = val / max;
        Assert.assert(0 <= val01 && val01 <= 1, this.constructor.name, this.name, "Value is not between 0 and 1");
        return val01;
    }


    // *** Info ***

    public getInfo(): string {
        let str: string = "";
        str += "State:\n";
        for(let elem of this.all) {
            str += elem.getInfo();
        }
        return str;
    }


    // *** Sun ***

    // Solar power of season: Returns a sinus value that simulates the solar power during the seasons, value in [0.2;1]
    private calcSolarPowerSeason021(): number {
        // Solar power, value in [0.2;1]
        this.solarPowerSeason021 = Math.cos(2 * Math.PI * this.getDatePercent() + Math.PI) * 0.4 + 0.6;
        Assert.assert(0.2 - Assert.EPS_DOUBLE <= this.solarPowerSeason021 && this.solarPowerSeason021 <= 1 + Assert.EPS_DOUBLE, this.constructor.name, this.name, "percent season not within range");
        return this.solarPowerSeason021;
    }

    public getSolarPowerSeason021(): number {
        return this.solarPowerSeason021;
    }

    // Solar power of day: Returns a sinus value that simulates the solar power during the day, value in [0;1]
    private calcSolarPowerDay01(): number {
        let solarPower: number = Math.cos(2 * Math.PI * this.tick / Control.TICKS_PER_DAY + Math.PI);
        if(solarPower < 0) {
            solarPower = 0;
        }
        Assert.assert(0 <= solarPower && solarPower <= 1, this.constructor.name, this.name, "solar power not within range");
        this.solarPowerDay01 = solarPower;
        return solarPower;
    }

    public getSolarPowerDay01(): number {
        return this.solarPowerDay01;
    }

    // Weather factor, values are returned between 0.5 and 1.0
    private getWeatherFactor(): number {
        switch(this.weatherCondition) {
            case WeatherCondition.BAD:
                return 0.2;
            case WeatherCondition.REGULAR:
                return 0.6;
            case WeatherCondition.GOOD:
                return 1.0;
            default:
                Assert.assert(false, this.constructor.name, this.name, "Weather condition does not exist");
                return -1;
        }
    }

    // Calculates the overall solar power regarding to season, day and weather
    private calcSolarPower(): number {
        // Solar power season, value in [0.2;1]
        const solarPowerSeason: number = this.calcSolarPowerSeason021();
        // Solar power day, value in [0;1]
        const solarPowerDay: number = this.calcSolarPowerDay01();
        // Weather
        const weatherFactor: number = this.getWeatherFactor();
        // Current power of sun
        const solarPowerCur: number = Control.SUN_MAX * solarPowerSeason * solarPowerDay * weatherFactor;
        this.setSolarPowerKw(solarPowerCur);
        return solarPowerCur;
    }

    public getSolarPowerKw(): number {
        return this.sun.getExpNeeded(this.tubeSunPv);
    }

    public getSolarPower01(): number {
        const ratio: number = this.getSolarPowerKw() / Control.SUN_MAX;
        Assert.assert(0 <= ratio && ratio <= 1, this.constructor.name, this.name, "percent value not between 0 and 1");
        return ratio;
    }

    public getSolarPowerPercent(): number {
        const per: number = this.getSolarPower01() * 100;
        Assert.assert(0 <= per && per <= 100, this.constructor.name, this.name, "percent value not between 0 and 100");
        return per;
    }

    public setSolarPowerKw(kw: number): void {
        //console.log("KW are: " + kw);
        this.sun.setExpNeeded(this.tubeSunPv, kw);
        this.pv.setExpNeeded(this.tubePvPowerGrid, Math.round(kw * Control.SUN_TO_ELECTRICITY));
    }



    // *** Date and time ***

    public getDatePercent(): number {
        const totalDaysInYear: number = Control.DAYS_PER_SEASON * 4;
        const dayInYear: number = Control.DAYS_PER_SEASON * this.season + this.day;
        return dayInYear / totalDaysInYear;
    }

    public getTime(): string {
        const dayPer: number = this.tick / Control.TICKS_PER_DAY;
        return "  " + Math.round(dayPer * 24) + " Uhr";
    }

    private getMonth(month: number): string {
        switch(month) {
            case  1:
                return "Januar";
            case  2:
                return "Februar";
            case  3:
                return "März";
            case  4:
                return "April";
            case  5:
                return "Mai";
            case  6:
                return "Juni";
            case  7:
                return "Juli";
            case  8:
                return "August";
            case  9:
                return "September";
            case 10:
                return "Oktober";
            case 11:
                return "November";
            case 12:
                return "Dezember";
            default:
                return "Monat";
        }
    }

    public getDate(): string {
        const datePer: number = this.getDatePercent();
        const dayInYear: number = datePer * Control.DAYS_PER_YEAR;
        const month = Math.floor(dayInYear / Control.DAYS_PER_MONTH);
        const day = Math.round(dayInYear - month * Control.DAYS_PER_MONTH);
        return "" + (day + 1) + ". " + this.getMonth(month + 1);
    }

    public getDateTime(): string {
        return this.getDate() + " " + this.getTime();
    }


    // *** PV ***

    public getPvOutputKw(): number {
        return this.pv.getExpNeeded(this.tubePvPowerGrid);
    }

    public getPowerOfDataCenter(): number {
        const energyServer: number = this.getServerPowerInputKw() * Control.PUE_FREE_COOLING;
        const energyCooling = this.chillerFreeCoolingPossible || this.chillerStatus !== ChillerStatus.ON_CHILLER ? 0 : this.chillerPowerInput;
        return energyServer + energyCooling;
    }

    // Returns the Renewable Energy Factor
    public getREF(): number {
        const renewable: number = this.getPvOutputKw();
        const powerDC: number = this.getPowerOfDataCenter();
        Assert.assert(0 <= renewable && 0 <= powerDC, this.constructor.name, this.name, "Renewable Energy Factor not within range");
        //Assert.assert(renewable + Assert.EPS_DOUBLE <= energy, this.constructor.name, this.name, "Renewable Energy Factor not within range");
        return Math.min(1, renewable / powerDC);
    }

    // Returns the Power Usage Effectivness
    public getPUE(): number {
        const powerDC: number = this.getPowerOfDataCenter();
        const powerServer: number = this.getServerPowerInputKw();
        Assert.assert(0 < powerServer && powerServer < powerDC, this.constructor.name, this.name, "PUE is wrong");
        return powerDC / powerServer;
    }


    // *** Chiller ***

    public getChillerOutputHeatKw(): number {
        return this.chiller.getExpNeeded(this.tubeChillerHeatStore);
    }

    public getChillerOutputHeat01(): number {
        return this.get01ExpNeeded(this.chiller, this.tubeChillerHeatStore);
    }

    public getChillerOutputColdKw(): number {
        return this.chiller.getExpNeeded(this.tubeChillerColdStore);
    }

    public getChillerOutputCold01(): number {
        return this.get01ExpNeeded(this.chiller, this.tubeChillerColdStore);
    }

    public getChillerOutputColdHeatKw(): number {
        return this.getChillerOutputHeatKw() + this.getChillerOutputColdKw();
    }

    public getChillerStatus(): string {
        switch(this.chillerStatus) {
            case ChillerStatus.OFF:
                return "Nicht in Betrieb";
                break;
            case ChillerStatus.ON_PV_AVAILABLE:
                return "Wärmeproduktion auf Vorrat";
                break;
            case ChillerStatus.ON_HEATPUMP:
                return "Wärmepumpe";
                break;
            case ChillerStatus.ON_CHILLER:
                return "Kältemaschine";
                break;
            default:
                return "Error";
                break;
        }
    }


    // *** Buffers ***

    public getHeatBufferStorageLevelPercent(): number {
        return this.heatBuffer.getStorageLevelByPercent();
    }

    public getColdBufferStorageLevelPercent(): number {
        return this.coldBuffer.getStorageLevelByPercent();
    }

    public getHeatbufferLevel01(): number {
        return this.heatBuffer.getStorageLevelByPercent() / 100;
    }

    public getColdbufferLevel01(): number {
        return this.coldBuffer.getStorageLevelByPercent() / 100;
    }


    // *** Exchanger ***

    public getExchangerColdKw(): number {
        return this.coldBuffer.getExpNeeded(this.tubeColdStoreExchanger);
    }

    public getExchangerHeatKw(): number {
        return this.heatBuffer.getExpNeeded(this.tubeHeatStoreExchanger);
    }


    // *** Server ***

    private setServerCoolingLow(): void {
        this.serverPowerFactor = 0.8;
    }

    private setServerCoolingNormal(): void {
        this.serverPowerFactor = 1.0;
    }

    public getServerPowerInputKw(): number {
        return this.server.getImpNeeded(this.tubePowerGridServer);
    }

    public getServerCoolingKw(): number {
        return this.serverCooling.getImpNeeded(this.tubeColdStoreServer);
    }

    public getServerCooling01(): number {
        return this.get01ImpNeeded(this.serverCooling, this.tubeColdStoreServer);
    }


    // *** Greenhouse ***

    public getGreenhouseHeatingKw(): number {
        return this.greenhouseHeating.getImpNeeded(this.tubeHeatStoreGreenhouse);
    }

    public getGreenhouseHeating01(): number {
        return this.get01ImpNeeded(this.greenhouseHeating, this.tubeHeatStoreGreenhouse);
    }

    public getGreenhouseHeatingFreeCooling01(): number {
        return this.chillerFreeCoolingPossible ? 1.0 : 0.0;
    }    


    // *** Sportscenter ***

    public setHeatDemandShowersKw(powerInput: number): void {
        this.sportscenterHeating.setImpNeeded(this.tubeHeatStoreSportscenter, powerInput);
    }

    public getHeatDemandShowersKw(): number {
        return this.sportscenterHeating.getImpNeeded(this.tubeHeatStoreSportscenter);
    }

    public getSportscenterKw(): number {
        return this.sportscenterHeating.getImpNeeded(this.tubeHeatStoreSportscenter);
    }

    public getSportscenter01(): number {
        return this.get01ImpNeeded(this.sportscenterHeating, this.tubeHeatStoreSportscenter);
    }


    // *** District Heating ***

    private setDistrictHeatingKw(powerToDistrictHeatingKw): void {
        this.districtHeatingHeating.setImpNeeded(this.tubeHeatStoreDistrictHeating, powerToDistrictHeatingKw);
    }

    public getDistrictHeatingKw(): number {
        return this.districtHeatingHeating.getImpNeeded(this.tubeHeatStoreDistrictHeating);
    }

    public getDistrictHeating01(): number {
        return this.get01ImpNeeded(this.districtHeatingHeating, this.tubeHeatStoreDistrictHeating);
    }

    public getMainDistrictHeating101(): number {
        return this.isPowerPlantOff ? 0.0 : 1.0;
    }

    public getMainDistrictHeating201(): number {
        return this.isPowerPlantOff ? (this.heatBuffer.isFull() ? 1.0 : 0.0) : 1.0;
        //return 1.0;
    }

    // *** Weather factors ***

    public getWeatherCondition(): WeatherCondition {
        return this.weatherCondition;
    }

    public getWeatherConditionString(): string {
        switch(this.weatherCondition) {
            case WeatherCondition.BAD:
                return "regnerisch";
            case WeatherCondition.REGULAR:
                return "leicht bewölkt";
            case WeatherCondition.GOOD:
                return "sonnig";
            default:
                Assert.assert(false, this.constructor.name, this.name, "Weather condition does not exist");
                return "ERROR";
        }
    }

    public setWeatherCondition(weatherCondition: WeatherCondition): void {
        this.weatherCondition = weatherCondition;
    }

    public isSnow(): boolean {
        const isWinter: boolean = this.getSolarPowerSeason021() < Control.SOLAR_POWER_FACTOR_SEASON_SNOW;
        const isWeatherGood: boolean = this.getWeatherCondition() === WeatherCondition.GOOD;
        return isWinter && !isWeatherGood;
    }


    // *** Others: Campus, Power plant, Showers of sportscenter ***

    public getHeatDemandCampusKw(): number {
        return this.campus.getImpNeeded(this.tubeDistrictHeatingCampus);
    }

    public setHeatOutputPowerPlantKw(powerOutput: number): void {
        this.districtHeatingHeating.setImpNeeded(this.tubePowerPlantDistrictHeating, powerOutput);
    }

    public getHeatOutputPowerPlantKw(): number {
        return this.districtHeatingHeating.getImpNeeded(this.tubePowerPlantDistrictHeating);
    }

}
