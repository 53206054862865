import { Assert } from '../assert';
import { SEUnit, Unit } from './unit';

export class Value {
    public unit: Unit;
    public valueMin: number = -1;
    public valueMax: number = -1;
    public value: number = -1;

    constructor(seunit: SEUnit, valueMin: number, valueMax: number, value: number) {
        this.unit = new Unit(seunit);
        this.valueMin = valueMin;
        this.valueMax = valueMax;
        this.value = value;
    }

    public setValue(value: number): void {
        //Assert.assert(this.isPassedValueInRange(value), this.constructor.name, this.name, "Value is not within allowed range: " + value + " !e [" + this.valueMin + "," + this.valueMax + "]");
        this.value = value;
    }

    public getValue(): number {
        //Assert.assert(this.isInRange(), this.constructor.name, this.name, "Value is not within allowed range.");
        return this.value;
    }

    public getValueByPercent(): number {
        //Assert.assert(this.isInRange(), this.constructor.name, this.name, "Value is not within allowed range.");
        // (val - min) / (max - min)
        const s: number = (this.value - this.valueMin) / (this.valueMax - this.valueMin) * 100;
        Assert.assert(0 <= s && s <= 100, this.constructor.name, this.name, "Value is not within allowed range.");
        return s;
    }

    public setValueByPercent(value: number): void {
        Assert.assert(0 <= value && value <= 100, this.constructor.name, this.name, "Value is not within allowed range.");
        const s: number = value / 100;
        Assert.assert(0 <= s && s <= 1, this.constructor.name, this.name, "Value is not within allowed range.");
        this.value = (1 - s) * this.valueMin + s * this.valueMax;
    }

    public addValue(valueToAdd: number): number {
        this.value += valueToAdd;
        let ret: number = 0;
        if(this.valueMax < this.value) {
            ret = this.value - this.valueMax;
            this.value = this.valueMax;
        }
        Assert.assert(this.isInRange(), this.constructor.name, this.name, "Value is not within allowed range.");
        return ret;
    }

    public isInRange(): boolean {
        return this.isPassedValueInRange(this.value);
    }

    public isPassedValueInRange(value: number): boolean {
        return this.valueMin <= value && value <= this.valueMax;
    }

    public getInfo(): string {
        let str: string = "";
        str += this.value + " in [" + this.valueMin + "," + this.valueMax + "]";
        return str;
    }

}
